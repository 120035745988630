@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
    @import url("./components/Navbar/Navbar.css");
    @import url("./components/Button/Button.css");
}

@layer base {
    html {
        box-sizing: border-box;       
        font-family: "Khula", system-ui, 'Roboto', sans-serif;
    }
    body {
        background: #F2F8F9;
    }
}

@layer utilities {
    .mb-section {
        @apply lg:mb-[180px] mb-[60px];
    }
}