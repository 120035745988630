    .navbar {
        @apply container mx-auto flex z-20 lg:justify-between items-center lg:h-[80px] justify-center;
    }

    .navbar a {
        @apply text-[14px] lg:text-[18px];
    }

    .navbar-menu-close {
        @apply left-0 bottom-[-100%] fixed;
    }

    .navbar-menu-open {
        @apply left-0 bottom-0 fixed;
    }

    .overlow-menu-open {
        @apply fixed top-0 left-0 w-full h-screen bg-black/50 transition-shadow; 
    }
    .overlow-menu-close {
        @apply fixed top-0 left-0 w-full h-screen bg-transparent invisible transition-shadow; 
    }