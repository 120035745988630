/* .btn .outline {
    @apply px-3 py-1 border rounded border-lb-dark text-lb-dark;
} */

/* .lb-btn .outline .lb {} */

.lb-btn {
    @apply px-6 py-3 rounded outline-none;
}

.lb-btn.small {
    @apply px-3 py-2 rounded text-sm;
}

.lb-btn.primary {
    @apply text-white bg-lb;
}

.lb-btn.primary.outline {
    @apply text-lb border border-lb bg-transparent;
}

.lb-btn.dark {
    @apply text-white bg-lb-dark;
}

.lb-btn.dark.outline {
    @apply text-lb-dark border border-lb-dark bg-transparent;
}

.lb-btn.dark {
    @apply text-white bg-lb-dark;
}

.lb-btn.brown {
    @apply text-white bg-brown;
}
